import styled from 'styled-components';

export * from './RelativeWrapper';
export * from './WidthWrapper';
export * from './ImgGatsbyBackground';

export const HeroSection = styled.div`
  
`;

export const BasicDiv = styled.div`
  
`;

export const TextDesc = styled.p`
  
`;