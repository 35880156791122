import styled, { css } from 'styled-components';

import { mediaMax } from 'common/styledMixins';
import {
  mainContainerWidth,
  zIndex,
  horizontalPadding,
  breakpoint,
  color,
} from 'common/styledVars';
import {
  BaseP,
  HeaderMedium,
  BaseTransparentP,
  MediumTextTransparent,
} from 'components/typography';

interface OwnProps {
  size?: number;
  whiteMobile?: boolean;
}

export const WidthWrapper = styled.div<OwnProps>`
  width: 100%;
  max-width: ${props =>
    (props.size ? props.size : mainContainerWidth.desktop) + 5 * horizontalPadding}px;
  padding: 0 ${horizontalPadding}px;
  margin: auto;
  display: flex;
  flex-direction: column;
  position: relative;
  z-index: ${zIndex.body + 1};

  ${props =>
    props.whiteMobile &&
    css`
      @media ${mediaMax(breakpoint.desktop - 1)} {
        ${BaseP} {
          color: ${color.white};
        }

        ${HeaderMedium} {
          color: ${color.white};
        }

        ${BaseTransparentP} {
          color: ${color.white};
        }

        ${MediumTextTransparent} {
          color: ${color.white};
        }
      }
    `};
`;


