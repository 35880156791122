import styled from 'styled-components';
import Img from 'gatsby-image';

import { zIndex } from 'common/styledVars';

export const ImgGatsbyBackground = styled(Img).attrs({
  style: { position: 'absolute' },
})`
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  object-fit: cover;
  height: 100%;
  width: 100%;
  z-index: ${zIndex.body};
`;
