import styled, { css } from 'styled-components';

interface OwnProps {
  breakLine?: boolean;
}

export const RelativeWrapper = styled.div<OwnProps>`
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;

  ${props =>
    props.breakLine &&
    css`
      &:after {
        position: absolute;
        content: ' ';
        bottom: 0;
        right: 0;
        left: 0;
        height: 1px;
        background-color: #ecf2f1;
      }
    `}
`;


export const MainSection = styled.div<OwnProps>`
 
`;

export const ColumnHalf = styled.div<OwnProps>`
 
`;


